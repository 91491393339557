/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-02-22",
    versionChannel: "nightly",
    buildDate: "2023-02-22T08:32:20.220Z",
    commitHash: "f7a12698b9a5dbfc39529ab5e4abd75880579b99",
};
