/**
 * The shadow template which is used to fill the actual Ruffle player element
 * on the page.
 */
export const ruffleShadowTemplate = document.createElement("template");
ruffleShadowTemplate.innerHTML = `
    <style>
        :host {
            --ruffle-blue: #37528c;
            --ruffle-orange: #ffad33;

            display: inline-block;
            position: relative;
            /* Default width/height; this will get overridden by user styles/attributes. */
            width: 760px;
            height: 680px;
            font-family: Arial, sans-serif;
            letter-spacing: 0.4px;
            touch-action: none;
            user-select: none;
            -webkit-user-select: none;
            -webkit-tap-highlight-color: transparent;
            line-height: normal;
            pointer-events: none;
        }

        /* Ruffle's width/height CSS interferes with Safari's fullscreen CSS. */
        /* Ensure that Safari's fullscreen mode actually fills the screen. */
        :host(:-webkit-full-screen) {
            display: block;
            width: 100% !important;
            height: 100% !important;
        }

        /* All of these use the dimensions specified by the embed. */
        #container {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            overflow: hidden;
        }

        #container canvas {
            width: 100%;
            height: 100%;
        }
    </style>
    <div id="container"></div>
`;
