/**
 * Conditional ruffle loader
 */
import { setPolyfillsOnLoad } from "./js-polyfills";
import { publicPath } from "./public-path";
import { DEFAULT_CONFIG } from "./config";
import { Localforage } from "./localforage";
/**
 * Load ruffle from an automatically-detected location.
 *
 * This function returns a new instance of Ruffle and downloads it every time.
 * You should not use it directly; this module will memoize the resource
 * download.
 *
 * @param config The `window.InluxRufflePlayer.config` object.
 * @param progressCallback The callback that will be run with Ruffle's download progress.
 * @returns A ruffle constructor that may be used to create new Ruffle
 * instances.
 */
async function fetchRuffle(config) {
    // Apply some pure JavaScript polyfills to prevent conflicts with external
    // libraries, if needed.
    setPolyfillsOnLoad();
    __webpack_public_path__ = publicPath(config);
    // Note: The argument passed to import() has to be a simple string literal,
    // otherwise some bundler will get confused and won't include the module?
    const { default: init, Ruffle } = await (import("../pkg/ruffle_web"));
    await Localforage.ready();
    let wasmArrayBuffer = null;
    const wasmUrl = new URL("../pkg/ruffle_web_bg.wasm", import.meta.url);
    try {
        wasmArrayBuffer = await Localforage.getItem(wasmUrl.href);
    }
    catch (error) {
    }
    if (!wasmArrayBuffer) {
        const wasmResponse = await fetch(wasmUrl);
        wasmArrayBuffer = await wasmResponse.arrayBuffer();
        await Localforage.setItem(wasmUrl.href, wasmArrayBuffer);
    }
    await init(wasmArrayBuffer);
    return Ruffle;
}
let lastLoaded = null;
/**
 * Obtain an instance of `Ruffle`.
 *
 * This function returns a promise which yields `Ruffle` asynchronously.
 *
 * @param config The `window.InluxRufflePlayer.config` object.
 * @param progressCallback The callback that will be run with Ruffle's download progress.
 * @returns A ruffle constructor that may be used to create new Ruffle
 * instances.
 */
export function loadRuffle(config = DEFAULT_CONFIG) {
    if (lastLoaded === null) {
        lastLoaded = fetchRuffle(config);
    }
    return lastLoaded;
}
