import * as Localforage from 'localforage';
const name = 'InluxHTML5';
const version = '001';
const expireStoreVersionList = [''];
expireStoreVersionList.forEach(expireVersion => {
    if (expireVersion != '') {
        expireVersion = '_' + expireVersion;
    }
    Localforage.ready().then(() => {
        Localforage.dropInstance({
            name: name,
            storeName: 'wasm_' + expireVersion
        });
    });
});
Localforage.config({
    driver: [Localforage.INDEXEDDB, Localforage.WEBSQL, Localforage.LOCALSTORAGE],
    name: name,
    storeName: 'wasm_' + version,
});
export { Localforage, };
